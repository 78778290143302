import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Raschin
City Game
California’s perennial financial troubles are well known. Without a national recession, the state struggles to maintain a functioning government. With one, it must underfund or shut down basic services such as education and parks. Yet in spite of the obvious cash flow problem, considerable attention is also given to issues surrounding the tax burden on the state’s citizenry.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/03/tax.jpg",
        "alt": "tax_1"
      }}></img></p>
    <p>{`We feel that, among the voting populace, there is a knowledge gap between taxes paid and state services consumed. Additionally, there may be disconnects between how proper funding of state services can help lead to a healthy economy and a better standard of living.`}</p>
    <p>{`California Constitution Visual Representation:
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/03/tg.jpg",
        "alt": "tax_2"
      }}></img></p>
    <p>{`I created a mock tourist guide that associates the California Constitution with a tangle of highway interchanges: an intimidating experience for a foreign driver, and a far cry from 1960s-era representations of California highways as symbols of freewheeling pleasure. CCA News.`}</p>
    <p>{`In order to understand concerns around the tax burden, we must first develop an understanding of the various perspectives people have. We first set out identify different classes, age groups, cultures, and political affiliations to target. Our primary research has focused on reaching out to target groups identified through interviews and an online survey.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/03/int1.jpg",
        "alt": "int_1"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/03/int2.jpg",
        "alt": "int_2"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/03/tax1.jpg",
        "alt": "int_3"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/03/onlinesurvey.jpg",
        "alt": "int_1"
      }}></img></p>
    <p>{`Overall, there seems to be lack of trust in California’s government to distribute tax money to the areas voters feel it is necessary. This results in a reluctance to any kind of tax reform, even though there seems to be agreement among most that California needs money to secure a better future. The top priority for most Californians seems to be education, and the least is prisons and administrative government services. Healthcare, the environment, and infrastructure are also of importance.`}</p>
    <p>{`Creating a source which will allow people to engage with the tax system in California, rather than ignore it, will help California voters make more informed decisions in regards to taxes and reform.`}</p>
    <p>{`As a team, we decided an exhibit, as a learning experience through interaction, would be the most effective format for our project.`}</p>
    <p>{`Contemporary issues exhibition | CCA 2010`}</p>
    <p>{` `}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/03/int04.jpg",
        "alt": "exhibit"
      }}></img></p>
    <p>{`City game( my individual project) is an interactive game that reflects changes upon an urban neighborhood depending on the way the budget is portioned. Participants can use the budget bar to adjust the amount of spending in each of three categories: health and human services, environment, and education. The total budget is limited and constant, however, and each adjustment will affect the other variables.`}</p>
    <p>{`gamestarted
`}<a parentName="p" {...{
        "href": "http://raschin.com/Processing/city/"
      }}>{`http://raschin.com/Processing/city/`}</a></p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/03/gamestarted.jpg",
        "alt": "game"
      }}></img></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      